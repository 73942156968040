import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"


export default function contact() {
  return (
    <Layout>
      <Seo title="Contact" />
      <div className="bg-white py-6 sm:py-8 lg:py-12">
  <div className="max-w-screen-2xl px-4 md:px-8 mx-auto">
    <h2 className="text-gray-800 text-2xl lg:text-3xl font-bold text-center mb-4 md:mb-8">Request an Estimate</h2>
    <span className="leading text-gray-80 text-center block mb-4 md:mb-8">Fill out the form below to request an estimate. We will get back with you as soon as we can. Be sure to leave a detailed message for a more accurate quote.</span>

    <form className="max-w-lg border rounded-lg mx-auto" action="https://getform.io/f/7b378aa0-10c4-495c-ae5e-995a8baba368" method="post">
      <div className="flex flex-col gap-4 p-4 md:p-8">
        <div>
          <label htmlFor="name" className="inline-block text-gray-800 text-sm sm:text-base mb-2">Name:</label>
          <input type='input' name="name" className="w-full bg-gray-50 text-gray-800 border focus:ring ring-indigo-300 rounded outline-none transition duration-100 px-3 py-2" placeholder='Enter Name' required />
        </div>

        <div>
          <label htmlFor="phoneNumber" className="inline-block text-gray-800 text-sm sm:text-base mb-2">Phone Number:</label>
          <input type='tel' name="phone" className="w-full bg-gray-50 text-gray-800 border focus:ring ring-indigo-300 rounded outline-none transition duration-100 px-3 py-2" required placeholder='Enter Phone Number' pattern='^\d{3}[- ]?\d{3}[- ]?\d{4}$' />
        </div>

        <div>
          <label htmlFor="email" className="inline-block text-gray-800 text-sm sm:text-base mb-2">Email Address:</label>
          <input name="email" type='email' className="w-full bg-gray-50 text-gray-800 border focus:ring ring-indigo-300 rounded outline-none transition duration-100 px-3 py-2" placeholder='Enter email' required />
        </div>

        <div>
          <label htmlFor="address" className="inline-block text-gray-800 text-sm sm:text-base mb-2">Address:</label>
          <input name="address" className="w-full bg-gray-50 text-gray-800 border focus:ring ring-indigo-300 rounded outline-none transition duration-100 px-3 py-2" placeholder='Enter address' required />
        </div>
    
        <div className="flex">
            <label>Property Type:</label>
            <div>
                <label className="ml-2.5" htmlFor="residentialRadio">
                    <input type="radio" id='residentialRadio' name='property' value='residential' required/> Residential
                </label>

                <label className="ml-2.5" htmlFor="commercialRadio">
                    <input type="radio" id='commercialRadio' name='property' value='commercial' required /> Commercial
                </label>
            </div>
        </div>


        <div>
          <label htmlFor="message" className="inline-block text-gray-800 text-sm sm:text-base mb-2">Message:</label>
          <textarea name="message" className="w-full bg-gray-50 text-gray-800 border focus:ring ring-indigo-300 rounded outline-none transition duration-100 px-3 py-2" placeholder='Enter Message' required></textarea>
        </div>

        <button className="block bg-blue-800 hover:bg-blue-700 active:bg-blue-600 focus-visible:ring ring-blue-300 text-white text-sm md:text-base font-semibold text-center rounded-lg outline-none transition duration-100 px-8 py-3">Submit</button>
      </div>
    </form>
  </div>
</div>
    </Layout>
  )
}
